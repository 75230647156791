<template>
  <v-container id="user" fluid tag="section">
    <v-card>
      <v-alert color="red lighten-2" dark v-if="showEmailError">
        {{ showErrorEmail }}
      </v-alert>
      <v-alert color="red lighten-2" dark v-if="showPhoneNumberError">
        {{ showErrorPhoneNumber }}
      </v-alert>
      <v-alert color="red lighten-2" dark v-if="showVehicleNumberError">
        {{ showErrorVehicleNumber }}
      </v-alert>
      <v-alert color="red lighten-2" dark v-if="showDriverLicenceNumberError">
        {{ showErrorDriverLicenceNumber }}
      </v-alert>
      <v-alert color="red lighten-2" dark v-if="showDriverPhotoError">
        {{ showErrorDriverPhoto }}
      </v-alert>
      <v-alert color="red lighten-2" dark v-if="showInsurancePhotoError">
        {{ showErrorInsurancePhoto }}
      </v-alert>
      <v-alert color="red lighten-2" dark v-if="showLicencePhotoError">
        {{ showErrorLicencePhoto }}
      </v-alert>
      <v-form ref="form">
        <v-container class="py-2">
          <v-row>
            <v-col cols="6" md="6">
              <v-text-field
                label="First Name"
                class="purple-input"
                v-model="first_name"
                :rules="inputRule"
                :disabled="onlyView"
              />
            </v-col>
            <v-col cols="6" md="6">
              <v-text-field
                label="Last Name"
                class="purple-input"
                v-model="last_name"
                :rules="inputRule"
                :disabled="onlyView"
              />
            </v-col>

            <v-col cols="6" md="6">
              <v-text-field
                label="Email"
                class="purple-input"
                v-model="email"
                :rules="emailRule"
                :disabled="onlyView"
              />
            </v-col>
            <v-col cols="6" md="6">
              <v-text-field
                label="Phone Number"
                class="purple-input"
                v-model="phone_number"
                :rules="inputRule"
                :disabled="onlyView"
              />
            </v-col>

            <v-col cols="6" md="6">
              <v-select
                :items="userType"
                label="Select User Type"
                item-text="name"
                item-value="_id"
                v-model="userTypeId"
                :rules="selectRule"
                :disabled="true"
              ></v-select>
            </v-col>
            <v-col
              cols="6"
              md="6"
              v-if="userTypeId != deliveryGuyId && userTypeId != customerId"
            >
              <v-select
                :items="stores"
                label="Select Store"
                item-text="name"
                item-value="_id"
                v-model="storeId"
                :rules="selectRule"
                :disabled="onlyView"
              ></v-select>
            </v-col>
            <v-col cols="6" md="6" v-if="!editUser">
              <v-text-field
                v-model="password"
                label="Password"
                hint="At least 6 characters"
                :type="show1 ? 'text' : 'password'"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                counter
                @click:append="show1 = !show1"
                :rules="passwordRule"
              ></v-text-field>
            </v-col>
          </v-row>
          <div v-if="userTypeId == deliveryGuyId">
            <h2>Driver Details</h2>
            <v-row>
              <v-col cols="6" md="6">
                <v-checkbox
                  @change="getVanList"
                  v-model="isGlugglerDriver"
                  :label="`Gluggler Driver: ${isGlugglerDriver == true ? 'YES' : 'NO'}`"
                  color="success"
                  hide-details
                  :disabled="onlyView || editUser"
                ></v-checkbox>
              </v-col>
              <v-col cols="6" md="6" v-if="editUser || onlyView">
                <v-select
                  @change="getVanDetails"
                  :items="vans"
                  label="Select Van"
                  item-text="vehicle_number"
                  item-value="_id"
                  v-model="assignVanId"
                  :disabled="onlyView || editUser"
                ></v-select>
                <v-btn
                  v-if="editUser"
                  type="button"
                  color="primary"
                  class="mr-0"
                  @click="releaseVan"
                  :disabled="assignVanId == null"
                >
                  Release Van
                </v-btn>
              </v-col>
            </v-row>
            <!-- <v-row>
              <v-col cols="6" md="6">
                <v-text-field
                  label="Vehicle Type"
                  class="purple-input"
                  v-model="vehicle_type"
                  :rules="inputRule"
                  :disabled="onlyView"
                />
              </v-col>
              <v-col cols="6" md="6">
                <v-text-field
                  label="Vehicle Model"
                  class="purple-input"
                  v-model="vehicle_model"
                  :rules="inputRule"
                  :disabled="onlyView"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" md="6">
                <v-text-field
                  label="Vehicle Number"
                  class="purple-input"
                  v-model="vehicle_number"
                  :rules="inputRule"
                  :disabled="onlyView"
                />
              </v-col>
              <v-col cols="6" md="6">
                <v-text-field
                  label="Vehicle Manufacturing Year"
                  class="purple-input"
                  v-model="vehicle_manufacturing_year"
                  :rules="inputRule"
                  :disabled="onlyView"
                />
              </v-col>
            </v-row> -->
            <v-row>
              <v-col cols="6" md="6">
                <v-text-field
                  label="Driver Licence Number"
                  class="purple-input"
                  v-model="driver_licence_number"
                  :rules="inputRule"
                  :disabled="onlyView"
                />
              </v-col>
              <v-col cols="6" md="6">
                <v-text-field
                  label="Age"
                  class="purple-input"
                  v-model="age"
                  :rules="inputRule"
                  :disabled="onlyView"
                />
              </v-col>
              <v-col cols="6" md="6" v-if="onlyView">
                <v-switch
                  @change="appliedDriver"
                  v-model="isVerified"
                  flat
                  :label="`Driver is Verified : ${isVerified.toString()}`"
                  :disabled="isVerified"
                ></v-switch>
                <v-dialog v-model="applyDriver" max-width="500px">
                  <v-card>
                    <v-card-title>Are you sure you want to Apply Driver?</v-card-title>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary darken-1" small text @click="closeDriver"
                        >Cancel</v-btn
                      >
                      <v-btn color="primary darken-1" text @click="applyDriverTrue"
                        >Apply</v-btn
                      >
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" md="6">
                <v-file-input
                  show-size
                  label="Upload Driver Photo"
                  counter
                  truncate-length="15"
                  @change="selectDriverPhoto"
                  v-model="driver_photo"
                  :disabled="onlyView"
                  accept="image/*"
                  :rules="inputRule"
                  prepend-icon="mdi-camera"
                ></v-file-input>
                <v-row v-if="driver_photo != null">
                  <v-col cols="2" md="2" style="padding-left: 30px">
                    <v-avatar>
                      <v-img
                        aspect-ratio="1"
                        :src="imageUrl + driver_photo"
                        :lazy-src="imageUrl + driver_photo"
                        @click="openNewTab(imageUrl + driver_photo)"
                        style="cursor: pointer"
                      />
                    </v-avatar>
                  </v-col>
                  <v-col>
                    <v-btn
                      :disabled="onlyView"
                      icon
                      x-small
                      rounded
                      color="error"
                      style="
                        background-color: #fff !important;
                        padding-right: 20px;
                        padding-top: 20px;
                      "
                      @click="deleteImage('driver_photo', driver_photo)"
                    >
                      <v-icon> mdi-delete </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-dialog v-model="imageDelete" max-width="500px">
                  <v-card>
                    <v-card-title>Are you sure you want to delete image?</v-card-title>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary darken-1" depressed @click="closeDelete"
                        >Cancel</v-btn
                      >
                      <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                        >Delete</v-btn
                      >
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
              <v-col cols="6" md="6">
                <v-file-input
                  show-size
                  label="Upload Insurance Photo"
                  counter
                  multiple
                  truncate-length="15"
                  @change="selectInsurancePhoto"
                  v-model="insurance_photo"
                  :disabled="onlyView"
                  accept="image/*"
                  :rules="imageRule"
                  prepend-icon="mdi-camera"
                ></v-file-input>
                <v-row v-for="image in insurance_photo" :key="image">
                  <v-col cols="2" md="2" style="padding-left: 30px">
                    <v-avatar>
                      <v-img
                        aspect-ratio="1"
                        :src="imageDriverUrl + image"
                        :lazy-src="imageDriverUrl + image"
                        @click="openNewTab(imageDriverUrl + image)"
                        style="cursor: pointer"
                      />
                    </v-avatar>
                  </v-col>
                  <v-col>
                    <v-btn
                      :disabled="onlyView"
                      icon
                      x-small
                      rounded
                      color="error"
                      style="
                        background-color: #fff !important;
                        padding-right: 20px;
                        padding-top: 20px;
                      "
                      @click="deleteImage('insurance_photo', image)"
                    >
                      <v-icon> mdi-delete </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" md="6">
                <v-file-input
                  show-size
                  label="Upload Licence Photo"
                  counter
                  multiple
                  truncate-length="15"
                  @change="selectLicencePhoto"
                  v-model="licence_photo"
                  :disabled="onlyView"
                  accept="image/*"
                  :rules="imageRule"
                  prepend-icon="mdi-camera"
                ></v-file-input>
                <v-row v-for="image in licence_photo" :key="image">
                  <v-col cols="2" md="2" style="padding-left: 30px">
                    <v-avatar>
                      <v-img
                        aspect-ratio="1"
                        :src="imageDriverUrl + image"
                        :lazy-src="imageDriverUrl + image"
                        @click="openNewTab(imageDriverUrl + image)"
                        style="cursor: pointer"
                      />
                    </v-avatar>
                  </v-col>
                  <v-col>
                    <v-btn
                      :disabled="onlyView"
                      icon
                      x-small
                      rounded
                      color="error"
                      style="
                        background-color: #fff !important;
                        padding-right: 20px;
                        padding-top: 20px;
                      "
                      @click="deleteImage('licence_photo', image)"
                    >
                      <v-icon> mdi-delete </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
          <v-row>
            <v-col cols="12" class="text-right">
              <v-btn
                type="button"
                color="primary"
                class="mr-0"
                @click="submit"
                :loading="loading"
              >
                {{ onlyView == true ? "Back" : editUser == true ? "Update" : "Submit" }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card>

    <driver-orders-by-status :driverId="id" v-if="editUser || onlyView" />
  </v-container>
</template>

<script>
export default {
  components: {
    DriverOrdersByStatus: () => import("./DriverOrdersByStatus.vue"),
  },
  props: ["id", "action", "value"],
  data() {
    return {
      editUser: false,
      showEmailError: false,
      showErrorEmail: "",
      showPhoneNumberError: false,
      showErrorPhoneNumber: "",
      showVehicleNumberError: false,
      showErrorVehicleNumber: "",
      showErrorDriverLicenceNumber: "",
      showDriverLicenceNumberError: false,
      showErrorDriverPhoto: "",
      showDriverPhotoError: false,
      showErrorInsurancePhoto: "",
      showInsurancePhotoError: false,
      showErrorLicencePhoto: "",
      showLicencePhotoError: false,
      stores: [],
      userType: [],
      vans: [],
      storeId: "",
      userTypeId: "",
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      password: "",
      assignVanId: "",
      vehicle_type: "",
      vehicle_model: "",
      vehicle_manufacturing_year: "",
      age: "",
      vehicle_number: "",
      driver_licence_number: "",
      isVerified: "",
      licence_photo: [],
      insurance_photo: [],
      driver_photo: null,
      show1: false,
      applyDriver: false,
      sendDriverPhoto: "",
      sendInsurencePhoto: [],
      sendLicencePhoto: [],
      imageDelete: false,
      deleteImageConfirm: "",
      deleteFieldConfirm: "",
      isGlugglerDriver: false,
      imageUrl: process.env.VUE_APP_Image_Link + "Users/",
      imageDriverUrl: process.env.VUE_APP_Image_Link + "Drivers/",
      deliveryGuyId: process.env.VUE_APP_Delivery_Guys_ID,
      customerId: process.env.VUE_APP_Customer_ID,
      inputRule: [(v) => !!v || "Field is required"],
      selectRule: [(v) => !!v || "Select any option"],
      passwordRule: [(v) => v.length >= 6 || "Minimum length is 6 characters"],
      imageRule: [
        (v) => !!v || "File is required",
        (v) => (v && v.length > 0) || "File is required",
      ],
      emailRule: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
      onlyView: false,
      loading: false,
    };
  },
  methods: {
    releaseVan() {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "user/removeVan/" + this.id)
        .then((response) => {
          if (response.status == 200) {
            this.getUserData(this.id);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    userTypeList() {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "userType/getall")
        .then((response) => {
          if (response.status == 200) {
            this.userType = response.data.user_type;
            if (this.onlyView != true && this.editUser != true) {
              // for default user type while adding new user
              this.userType.forEach((element) => {
                if (element.name == "Delivery Guys") {
                  this.userTypeId = element._id;
                }
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getStoreList() {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "store/getall")
        .then((response) => {
          if (response.status == 200) {
            this.stores = response.data.stores;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getVanList() {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "van/getall")
        .then((response) => {
          if (response.status == 200) {
            this.vans = response.data.vans;
          }
        })
        .catch((error) => {
          console.log(error);
        });
      // if (this.isGlugglerDriver == false) {
      //   this.assignVanId = "";
      //   this.vehicle_type = "";
      //   this.vehicle_model = "";
      //   this.vehicle_manufacturing_year = "";
      //   this.vehicle_number = "";
      // }
    },
    getVanDetails() {
      this.vans.forEach((van) => {
        if (van._id == this.vanId) {
          this.vehicle_type = van.vehicle_type;
          this.vehicle_model = van.vehicle_model;
          this.vehicle_manufacturing_year = van.vehicle_manufacturing_year;
          this.vehicle_number = van.vehicle_number;
        }
      });
    },
    getUserData(id) {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "user/edit/" + id)
        .then((response) => {
          if (response.status == 200) {
            this.first_name = response.data.user.first_name;
            this.last_name = response.data.user.last_name;
            this.email = response.data.user.email;
            this.phone_number = response.data.user.phone_number;
            this.userTypeId = response.data.user.user_type[0]._id;
            if (this.userTypeId == this.deliveryGuyId) {
              this.age = response.data.user.age;
              this.vehicle_type = response.data.user.vehicle_type;
              this.vehicle_model = response.data.user.vehicle_model;
              this.vehicle_manufacturing_year =
                response.data.user.vehicle_manufacturing_year;
              this.vehicle_number = response.data.user.vehicle_number;
              this.driver_licence_number = response.data.user.driver_licence_number;
              this.licence_photo = response.data.user.licence_photo;
              this.insurance_photo = response.data.user.insurance_photo;
              this.driver_photo = response.data.user.driver_photo;
              this.isVerified = response.data.user.isVerified;
              this.isGlugglerDriver = response.data.user.isGlugglerDriver;
              this.assignVanId = response.data.user.vanId;
            } else {
              this.storeId = response.data.user.store_name[0]._id;
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    selectDriverPhoto() {
      this.sendDriverPhoto = this.driver_photo;
    },
    selectInsurancePhoto() {
      this.insurance_photo.forEach((file) => {
        this.sendInsurencePhoto.push(file);
      });
    },
    selectLicencePhoto() {
      this.licence_photo.forEach((file) => {
        this.sendLicencePhoto.push(file);
      });
    },
    appliedDriver() {
      this.applyDriver = true;
    },
    closeDriver() {
      this.isVerified = false;
      this.applyDriver = false;
    },
    applyDriverTrue() {
      let data = { id: this.id, isVerified: true };
      axios
        .post(process.env.VUE_APP_API_BASE_URL + "applyDriver", data)
        .then((response) => {
          if (response.status == 200) {
            this.applyDriver = false;
            this.getUserData(this.id);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    openNewTab(url) {
      window.open(url, "_blank");
    },
    deleteImage(field, image) {
      this.imageDelete = true;
      this.deleteImageConfirm = image;
      this.deleteFieldConfirm = field;
    },
    closeDelete() {
      this.imageDelete = false;
      this.deleteImageConfirm = "";
      this.deleteFieldConfirm = "";
    },
    deleteItemConfirm() {
      this.imageDelete = false;
      let data = {
        id: this.id,
        name: this.deleteImageConfirm,
        field: this.deleteFieldConfirm,
      };
      axios
        .post(process.env.VUE_APP_API_BASE_URL + "user/delete/image", data)
        .then((response) => {
          if (response.status == 200) {
            this.closeDelete();
            this.getUserData(this.id);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    submit() {
      this.showEmailError = false;
      this.showPhoneNumberError = false;
      this.loading = true;
      let formData = new FormData();
      if (this.editUser == true) {
        formData.append("id", this.id);
      }
      formData.append("first_name", this.first_name);
      formData.append("last_name", this.last_name);
      formData.append("email", this.email);
      formData.append("phone_number", this.phone_number);
      formData.append("user_type_id", this.userTypeId);
      formData.append("store_id", this.storeId);
      formData.append("password", this.password);
      formData.append("age", this.age);
      formData.append("vehicle_type", this.vehicle_type);
      formData.append("vehicle_model", this.vehicle_model);
      formData.append("isGlugglerDriver", this.isGlugglerDriver);
      formData.append("vanId", this.assignVanId);
      formData.append("vehicle_manufacturing_year", this.vehicle_manufacturing_year);
      formData.append("vehicle_number", this.vehicle_number);
      formData.append("driver_licence_number", this.driver_licence_number);
      formData.append("driver_photo", this.sendDriverPhoto);
      for (let file of this.sendLicencePhoto) {
        formData.append("licence_photo[]", file, file.name);
      }
      for (let file of this.sendInsurencePhoto) {
        formData.append("insurance_photo[]", file, file.name);
      }

      // // Display the key/value pairs
      // for (var pair of formData.entries()) {
      //   console.log(pair[0] + ", " + pair[1]);
      // }

      if (this.onlyView) {
        this.$router.push({ name: "Drivers" });
      } else if (this.editUser == true) {
        axios
          .post(process.env.VUE_APP_API_BASE_URL + "user/update", formData)
          .then((response) => {
            if (response.status == 200) {
              console.log(response);
              this.loading = false;
              this.$router.push({ name: "Drivers", params: { edit: true } });
            }
          })
          .catch((error) => {
            this.loading = false;
            if (error.response.data.email != null) {

              this.showErrorEmail = error.response.data.email[0];
              this.showEmailError = true;
            }
            if (error.response.data.phone_number != null) {
              this.showErrorPhoneNumber = error.response.data.phone_number[0];
              this.showPhoneNumberError = true;
            }
            if (error.response.data.vehicle_number != null) {
              this.showErrorVehicleNumber = error.response.data.vehicle_number[0];
              this.showVehicleNumberError = true;
            }
            if (error.response.data.driver_licence_number != null) {
              this.showErrorDriverLicenceNumber =
                error.response.data.driver_licence_number[0];
              this.showDriverLicenceNumberError = true;
            }
          });
      } else {
        axios
          .post(process.env.VUE_APP_API_BASE_URL + "user/create", formData)
          .then((response) => {
            if (response.status == 200) {
              this.loading = false;
              this.$router.push({ name: "Drivers", params: { add: true } });
            }
          })
          .catch((error) => {
            this.loading = false;
            if (error.response.data.email != null) {
              this.showErrorEmail = error.response.data.email[0];
              this.showEmailError = true;
            }
            if (error.response.data.phone_number != null) {
              this.showErrorPhoneNumber = error.response.data.phone_number[0];
              this.showPhoneNumberError = true;
            }
            if (error.response.data.vehicle_number != null) {
              this.showErrorVehicleNumber = error.response.data.vehicle_number[0];
              this.showVehicleNumberError = true;
            }
            if (error.response.data.driver_licence_number != null) {
              this.showErrorDriverLicenceNumber =
                error.response.data.driver_licence_number[0];
              this.showDriverLicenceNumberError = true;
            }
            if (error.response.data.driver_photo != null) {
              this.showErrorDriverPhoto = error.response.data.driver_photo[0];
              this.showDriverPhotoError = true;
            }
            if (error.response.data.insurance_photo != null) {
              this.showErrorInsurancePhoto = error.response.data.insurance_photo[0];
              this.showInsurancePhotoError = true;
            }
            if (error.response.data.licence_photo != null) {
              this.showErrorLicencePhoto = error.response.data.licence_photo[0];
              this.showLicencePhotoError = true;
            }
          });
      }
      // }
    },
  },
  mounted() {
    const route = this.$router.currentRoute;
    this.editUser = false;
    this.userTypeList();
    this.getStoreList();
    this.getVanList();
    if (this.id != undefined) {
      this.getUserData(this.id);
    }
    if (route.name == "View Driver") {
      this.onlyView = true;
    }
    if (route.name == "Edit Driver") {
      this.editUser = true;
    }
  },
};
</script>

<style></style>
